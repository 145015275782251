//https://coolors.co/0596ed-342e37-a2d729-b084cc-ba1f33
$blue: #0596ED;
$green: #A2D729;
$red: #BA1F33;
$dark: #342E37;
$purple: #B084CC;

$headings-font-weight: 700;
$line-height-base: 2;
$btn-font-weight: 700;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;

@import "bootstrap/bootstrap";

.bg-green {background-color: $green;}
.bg-red {background-color: $red;}
.bg-purple {background-color: $purple;}

body {
	font-family: 'Poppins', sans-serif;
}

.line {
	height: 4px;
	width: 120px;
	margin: auto;
}

.btn-primary {
    @include button-variant(
		$primary,
		darken($primary, 7.5%),
		white,
		darken($primary,5%),
		lighten($primary, 10%),
		white);
}

.page-header {
	height: 480px;
	background-size: cover;
	background-position: center center;
	color: white;
	display: flex;
	align-items: flex-end;

	h1, p {
		font-weight: 800;
		text-shadow: 2px 2px 4px #505050;
	}
}

.homepage-header {
	height: 800px;
	background-image: url('/assets/img/homepage-image.webp');
	background-size: cover;
	background-position: center top;
	background-attachment: fixed;
	display: flex;
	align-items: center;

	h1, h5 {
		font-weight: 800;
		color: white;
		text-shadow: 2px 2px 4px #505050;
	}

	h5 {
		font-size: 35px;
	}

	div.card {
		padding: 1em;
		max-width: 500px;
		margin: auto;
		margin-top: 3em;
		background-color: rgba(255,255,255,0.9);

		em {
			font-weight: bold;
		}

		h6 {
			font-size: 1.5em;
			color: #BA1F33;
		}
	}
}

.bottom-bar {
	background: darken($dark, 5%);
	font-size: 80%;
}
